import Dimens from '../../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the DetailTab component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const {xs, sm, lg, xl, box, section} = Dimens.spacing;
  const theme = useTheme();

  const styles = {
    wrapper: {
      display: 'flex',
      height: 'inherit'
    },
    innerWrapper: {
      flex: 1,
      p: section,
      [theme.breakpoints.down('sm')]: {
        p: lg
      }
    },
    spacer: {
      height: box
    },
    licenceWrapper: {
      flexDirection: 'row',
      alignItems: 'start'
    },
    reportWrapper: {
      pl: sm
    },
    dummyPlaceholder: {
      pb: xs,
      fontWeight: Dimens.fontWeight.semiBold,
      visibility: 'hidden'
    },
    footer: {
      borderTop: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
      bottom: 0,
      py: xl,
      bgcolor: theme.palette.background.paper,
      position: 'sticky',
      textAlign: 'end',
      [ theme.breakpoints.down('sm') ]: {
        textAlign: 'center',
        display: 'flex',
        '& button': {
          flex: 1
        }
      }
    },
    icon: {
      fontSize: `${Dimens.icon.sm} !important`
    },
    form: {
      flex: 1,
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      height: '100%'
    },
    editText: {
      cursor: 'pointer',
      color: theme.palette.primary.main
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    cancelButton: {
      display: 'flex',
      flex: 1
    },
    saveButton: {
      display: 'flex',
      flex: 1,
      ml: xl
    }
  };

  return styles;
};

export default useStyles;