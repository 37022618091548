import { AdminStatus } from '../../../../utils/constants';
import Dimens from '../../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the AdminsTab component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

	const { xs, lg, xl, section } = Dimens.spacing;
	const theme = useTheme();

	const styles = {
		wrapper: {
			display: 'flex',
			height: 'inherit'
		},
		innerWrapper: {
			flexDirection: 'column',
			display: 'flex',
			flex: 1,
			p: section,
			[ theme.breakpoints.down('sm') ]: {
				p: lg
			}
		},
		searchHeader: {
			display: 'flex',
			flexDirection: 'row',
			pb: section,
			[ theme.breakpoints.down('sm') ]: {
				pb: lg
			}
		},
		searchBarWrapper: {
			flex: 1,
			mr: xs
		},
		icon: {
			fontSize: `${Dimens.icon.sm} !important`
		},
		tableContainer: {
			flex: 1,
			backgroundImage: 'none',
			border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
			borderRadius: Dimens.radius.md,
			boxShadow: 'none',
			'& th': {
				bgcolor: theme.palette.background.paper,
				borderBottom: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
			},
			'& td': {
				borderBottom: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
			}
		},
		footer: {
			my: xl,
			borderTop: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
			bottom: 0,
			px: 0,
			pt: xl,
			bgcolor: theme.palette.background.paper,
			position: 'sticky',
			textAlign: 'end',
			[ theme.breakpoints.down('sm') ]: {
				textAlign: 'center',
				display: 'flex',
				'& button': {
					flex: 1
				}
			}
		},
		statusCell: (status: string) => ({
			textTransform: 'capitalize',
			display: 'flex',
			alignItems: 'center',
			color: 
				status === AdminStatus.Active
				? theme.palette.success.main
				: status === AdminStatus.Inactive
					? theme.palette.error.main          
					: theme.palette.grey[ 500 ]
		}),
		statusDot: (status: string) => ({
			height: Dimens.statusIconSize,
			width: Dimens.statusIconSize,
			borderRadius: Dimens.radius.sm,
			backgroundColor: 
				status === AdminStatus.Active
				? theme.palette.success.main        
				: status === AdminStatus.Inactive
					? theme.palette.error.main          
					: theme.palette.grey[ 500 ],
			marginRight: Dimens.spacing.sm 
		}),
		binIcon:{
			fontSize: `${Dimens.icon.sm} !important`,
			color: theme.palette.error.main 
		}
	};

	return styles;
};

export default useStyles;