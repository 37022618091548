import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CREATE_ORG, UPDATE_ORG } from '../../../../data/organization/action-types';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import ActivateOrganizationImage from '../../../../assets/org_activate.svg';
import AlertDialog from '../../../../components/AlertDialog';
import CustomButton from '../../../../components/CustomButton';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import CustomInput from '../../../../components/CustomInput';
import CustomInputSelect from '../../../../components/CustomInputSelect';
import DeactivateOrganizationImage from '../../../../assets/org_deactivate.svg';
import InlineAlert from '../../../../components/InlineAlert';
import { OrgDetailSchema } from '../../../../utils/validation-schema';
import { OrgStatus } from '../../../../utils/constants';
import Organization from '../../../../types/organization';
import OrganizationDetail from '../../../../types/organization-detail';
import PencilIcon from '../../../../components/CustomIcons/PencilIcon';
import SaveIcon from '../../../../components/CustomIcons/SaveIcon';
import { Store } from '../../../../store/store';
import Util from '../../../../utils/util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface AlertInfo {
	title: string;
	message: string;
	actionText: string;
	titleImage: string;
}

interface Props {
	organization: Organization;
	handleOrgDetailChange: (orgDetail: OrganizationDetail) => void;
	onOrgActivate: () => void;
}

/**
 * Detail Tab for Organization Management
 *
 * This component renders the detail tab within the organization management view.
 * It allows editing organization details like name, license, support email, and description.
 * It also displays a button to activate/deactivate the organization based on its current status.
 * 
 * @props { Props } - The component properties.
 * @property { OrganizationDetail } orgDetail - The organization data object to be displayed and edited.
 * @property { (orgDetail: OrganizationDetail) => void } handleOrgDetailChange - Callback function to handle changes in organization details.
 *
 * @returns {JSX.Element} - The rendered JSX element for the detail tab.
 */
const DetailTab: React.FC<Props> = (props: Props) => {

	const styles = useStyles();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { t } = useTranslation();
	const [ showAlert, setShowAlert ] = useState<boolean>(false);
	const [ orgDetail, setOrgDetail ] = useState<OrganizationDetail>(props.organization?.details);
	const [ isReadOnly, setIsReadOnly ] = useState<boolean>(true);
	const { state } = React.useContext(Store);

	/**
	 * Updates the `orgDetail` state with the `details` property from the `props.organization` object.
	 * This effect runs whenever the `props.organization` prop changes.
	 */
	useEffect(() => {
		if (props.organization && props.organization.details) {
			setOrgDetail(props.organization.details);
			props.organization.details.id ? setIsReadOnly(true) : setIsReadOnly(false);
		}
	}, [ props.organization, props.organization.details.status ]);

	/**
	 * Constructs the alert information based on the provided organization status.
	 * 
	 * @param {string} status - The organization status (Active, Inactive, Draft).
	 * @returns {AlertInfo} - An object containing information for the alert dialog.
	 */
	const getAlertInfo = (status?: string): AlertInfo => {
		switch (status) {
			case OrgStatus.Active:
				return {
					title: t('deactivateOrg'),
					message: t('deactivateOrgMsg'),
					actionText: t('deactivate'),
					titleImage: DeactivateOrganizationImage
				};
			case OrgStatus.Inactive:
				return {
					title: t('reactivateOrg'),
					message: t('reactivateOrgMsg'),
					actionText: t('reactivate'),
					titleImage: ActivateOrganizationImage
				};
			case OrgStatus.Draft:
				return {
					title: t('activateOrg'),
					message: t('activateOrgMsg'),
					actionText: t('activate'),
					titleImage: ActivateOrganizationImage
				};
			default:
				return {
					title: t('activateOrg'),
					message: t('activateOrgMsg'),
					actionText: t('activate'),
					titleImage: ActivateOrganizationImage
				};
		}
	};

	/**
	 * Handles form submission, currently logs a message to console for demonstration.
	 * 
	 * @param {OrganizationDetail} values - The submitted organization detail object.
	 */
	const onSubmit = (values: OrganizationDetail) => {
		props.handleOrgDetailChange(values);
	};

	/**
	 * Handles the activation click event for the organization.
	 *
	 * @returns {void} - This function does not return a value; it performs side effects related to organization activation.
	 */
	const OnActivateClick = () => {
		props.onOrgActivate();
		setShowAlert(false);
	};

	return (
		<Grid sx={styles.wrapper}>
			<Grid sx={styles.innerWrapper}>
				<Formik
					enableReinitialize
					validateOnMount
					validationSchema={OrgDetailSchema}
					initialValues={orgDetail}
					onSubmit={values => onSubmit(values)}>
					{({ isValid, resetForm, dirty }) => (
						<Form style={styles.form as React.CSSProperties}>
							<Box>
							<InlineAlert message={Util.getApiError([CREATE_ORG,UPDATE_ORG], state.orgState.apiStatus)} />
								<Field name='name' label={t('orgName')} placeholder={t('name')} readOnly={isReadOnly} component={CustomInput} />
								<Stack sx={styles.licenceWrapper}>
									<Field
										name='licenceId'
										label={t('licence')}
										placeholder={t('pleaseSelect')}
										disabled={isReadOnly}
										component={CustomInputSelect}
										menu={state.orgState.licenseList}
									/>
									{orgDetail.id &&
										<Stack sx={styles.reportWrapper}>
											<Typography variant='p1' sx={styles.dummyPlaceholder}>
												{isMobile ? t('report') : t('createReport')}
											</Typography>
											<CustomButton disabled={isReadOnly} title={isMobile ? t('report') : t('createReport')} color='primary' />
										</Stack>
									}
								</Stack>
								<Field
									name='expiryAt'
									label={t('expiryDate')}
									placeholder={'DD/MM/YYYY'}
									readOnly={isReadOnly}
									component={CustomDatePicker}
								/>
								<Field
									name='supportEmail'
									label={t('supportEmail')}
									placeholder={t('emailAddress')}
									readOnly={isReadOnly}
									component={CustomInput}
								/>
								<Field
									multiline
									name='description'
									label={t('description')}
									subLabel={t('optional')}
									placeholder={t('description')}
									readOnly={isReadOnly}
									component={CustomInput}
								/>
								{(orgDetail.id && isReadOnly) &&
									<Typography variant='p1' sx={styles.editText} onClick={() => setIsReadOnly(!isReadOnly)}>
										<PencilIcon sx={styles.icon} /> {t('editDetails')}
									</Typography>}
							</Box>
							<Box sx={styles.footer}>
								{(orgDetail.id && isReadOnly && orgDetail.status === OrgStatus.Draft) &&
									<Box>
										<CustomButton
											color='primary'
											fullWidth
											title={getAlertInfo(orgDetail.status).actionText}
											// destructive={orgDetail.status === OrgStatus.Active} // hide deactivate button for now
											onClick={() => setShowAlert(true)}
										/>
									</Box>
								}
								{(orgDetail.id && !isReadOnly) &&
									<Box sx={styles.buttonContainer}>
										<Box sx={styles.cancelButton}><CustomButton
											color='secondary'
											fullWidth
											title={t('cancel')}
											onClick={() => resetForm()}
										/></Box>
										<Box sx={styles.saveButton}>
											<CustomButton
												type='submit'
												color='primary'
												fullWidth
												title={t('save')}
											/>
										</Box>
									</Box>
								}
								{!orgDetail.id &&
									<CustomButton type='submit' title={t('save')} color='primary' disabled={!isValid} endIcon={<SaveIcon sx={styles.icon} />} />
								}
							</Box>
						</Form>
					)}
				</Formik>
			</Grid>
			<AlertDialog
				isLarge
				open={showAlert}
				title={getAlertInfo(orgDetail.status).title}
				message={getAlertInfo(orgDetail.status).message}
				titleIcon={getAlertInfo(orgDetail.status).titleImage}
				secondaryLabel={t('cancel')}
				onSecondaryAction={() => setShowAlert(false)}
				primaryLabel={getAlertInfo(orgDetail.status).actionText}
				onPrimaryAction={OnActivateClick}
				isDestructive={orgDetail.status === OrgStatus.Active}
				onClose={() => setShowAlert(false)}
			/>
		</Grid>
	);
};

export default DetailTab;