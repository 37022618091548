import {
  AppBar,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { AppearanceMenu } from '../../../../utils/constants';
import Box from '@mui/material/Box/Box';
import CheckIcon from '../../../../components/CustomIcons/CheckIcon';
import Dimens from '../../../../theme/dimens';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LoginUtil from '../../../../utils/login-util';
import LogoutIcon from '../../../../components/CustomIcons/LogoutIcon';
import MenuData from '../../../../types/ui/menu-data';
import MenuIcon from '@mui/icons-material/Menu';
import UserIcon from '../../../../components/CustomIcons/UserIcon';
import { colorModeManager } from '../../../../theme/color-mode-manager';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

export interface Props {
  onLogOutClick: () => void;
}

/**
 * TopBar component renders the application header with logo, user menu, and hamburger menu.
 * 
 * Functionality:
 *  - Displays application logo.
 *  - Shows user name and icon.
 *  - Opens user menu on user icon click.
 *  - Theme selection menu
 *  - User menu offers a "Logout" option.
 *  - Hamburger menu icon for mobile navigation (functionality not implemented in this component).
 * 
 * Props:
 *  - `onLogOutClick` (function): A function that gets called when the user clicks the logout option
 *    in the user menu. This function is likely responsible for initiating the logout process (e.g.,
 *    clearing session data, redirecting to login).
 *
 * @returns {JSX.Element} - The rendered main application layout component.
 */
const TopBar: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mode, setMode] = useState<string>('system');

  /**
   * Load the preferred theme mode from localStorage on component mount
   */
  useEffect(() => {
    const mode = localStorage.getItem('@aurora-app-color-mode');
    mode ? setMode(mode) : setMode('system');
  }, []);

  /**
   * Function to handle hamburger menu click (placeholder for future implementation)
   */
  const onHamburgerMenuClick = () => {
    console.log('onHamburgerMenuClick');
  }

  /**
   * Handles logout menu item click.
   * Calls the logout API to invalidate the user session on the server
   * and clears local session details.
   */
  const onLogOutClick = () => {
    setAnchorEl(null);
    props.onLogOutClick();
  }

  /**
   * Handles the selection of a new theme option.
   * 
   * Updates the theme mode via colorModeManager and reloads the page to apply the new theme.
   * 
   * @param {MenuData} item - The selected menu item representing the new theme
   */
  const handleThemeChange = (item: MenuData) => {
    colorModeManager.set(item.id.toString());
    location.reload();
  }

  /**
  * Renders a theme option menu item.
  * Includes an icon to indicate the currently selected theme mode.
  * 
  * @param {MenuData} option - The theme option to render.
  * @returns {JSX.Element} - The rendered MenuItem element for the theme option.
  */
  const renderThemeMenuItem = (option: MenuData) => (
    <MenuItem key={option.id} onClick={() => handleThemeChange(option)} sx={styles.menuItem}>
      <ListItemText>
        <Typography variant={'p1'}>{option.value}</Typography>
      </ListItemText>
      <ListItemIcon sx={styles.iconWrapper}>
        {option.id === mode && <CheckIcon sx={styles.checkIcon} />}
      </ListItemIcon>
    </MenuItem>
  );

  return (
    <AppBar position='fixed' sx={styles.appBar}>
      <Toolbar>
        <IconButton
          size='large'
          edge='start'
          aria-label='menu'
          onClick={onHamburgerMenuClick}
        >
          <MenuIcon  />
        </IconButton>
        <Box component='img' alt='logo' sx={styles.logo} />
        <Box flexGrow={1} />
        <Box>
          <MenuItem onClick={(event) => setAnchorEl(event.currentTarget)} sx={styles.toolBarMenu}>
            <ListItemIcon>
              <UserIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography variant='p1' fontWeight={Dimens.fontWeight.medium}>{LoginUtil.getAdminInfo()}</Typography>
            </ListItemText>
          </MenuItem>
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            slotProps={{paper: {sx: styles.menuPaperProps}}}
            MenuListProps={{ sx: styles.menu }}
          >
            <Typography variant='p1' sx={styles.label}>
              {t('appearance')} <span>:</span>
            </Typography>
            {AppearanceMenu().map(renderThemeMenuItem)}
            <Divider sx={styles.divider} />
            <MenuItem onClick={onLogOutClick} sx={{ ...styles.menuItem, ...styles.menuDestructive }}>
              <ListItemIcon sx={styles.iconWrapper}>
                <LogoutIcon sx={styles.icon} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant='p1' sx={styles.destructive}>{t('signOut')}</Typography>
              </ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
